/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 28, 2017 */



@font-face {
    font-family: 'bebas_neuebold';
    src: url('../fonts/bebasneue_bold_0-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_bold_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'bebas_neuebook';
    src: url('../fonts/bebasneue_book_0-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_book_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'bebas_neuelight';
    src: url('../fonts/bebasneue_light_0-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_light_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'bebas_neue_regularregular';
    src: url('../fonts/bebasneue_regular_0-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_regular_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'bebas_neuethin';
    src: url('../fonts/bebasneue_thin_0-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_thin_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'bebas_neueregular';
    src: url('../fonts/bebasneue_0-webfont.woff2') format('woff2'),
         url('../fonts/bebasneue_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}