body{
  font-family: 'Lato', sans-serif;
  overflow: hidden;
} 
img{
  max-width:100%;
}
.hide{
  display: none;
}
.invisible{
  opacity: 0;
  //visibility: hidden; 
}
.cf{
  @include clearfix;
}
.wrapper{
    margin:0 auto;
    max-width: 1024px;
    padding:0 30px;
}
a{
    transition: color .3s;
}

.video-wrapper, .video-wrapper video{
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    z-index: -100;
    overflow: hidden;
}
.video-wrapper{
    &::before{
        position: absolute;
        width:100%;
        height:100%;
        display: block;
        content:"";
        transition: background .3s;
    }
    &.overlay::before{
        background: rgba(24,17,31,0.8);
    }
    video {
        background: url(../images/poster.jpg) 50% 50%;
        background-size: cover;
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        //transform: scale(1.4);
        object-fit: cover;
    }
}

header{
    position: relative;
    padding-top: 30px;
    z-index: 10;
    .logo{
        width: 200px;
        float:left;
        display: block;
    }
    .menu{
        float:right;
        margin-top: 15px;
        li{
            line-height: 40px;
            display: inline-block;
            margin:0 25px;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
        a{
            color:#fff;
            &.active{
                color:#ff0000;
            }
        }
    }

    @include media(">=desktop"){
        .menu a:hover{
            color:#ff0000;
        }
    }

    @include media("<=tablet"){
        .logo{
            width: 200px;
        }
        .menu{
            margin-top: 15px;
            li{
                //font-size: 22px;
            }
        }
    }

    @include media("<628px"){
        .logo{
            float:none;
            width: 160px;
            margin: 0 auto;
        }
        .menu{
            position: absolute;
            right:-12px;
            text-align: right;
            display: none;
            
            li{
                display: block;
            }
        }
    }

    @include media(">=628px"){
        .menu{
            opacity: 1 !important;
            display: block !important;
        }
    }

    @include media("<=phone"){
        .menu li{
            font-size: 18px;
        }
    }
}

.message{
    margin-top: -80px;
    color:#fff;
    font-size: 28px;
    letter-spacing: 3px;
    text-align: center;
    line-height: 38px;
}


.forms form{
    font-family: 'bebas_neuebook';
    position: absolute;
    top:50%;
    left:50%;
    width:100%;
    max-width: 480px;
    transform: translate(-50%, -50%);
    display: none;
    padding:20px 20px 0;

    .form-text{
        position: relative;
        margin-bottom: 30px;

        label {            
            z-index: -1;
            span{
                font-size: 18px;
                letter-spacing: 2px;
                position: absolute;
                display:block;
                width: 100%;
                top:0;
                color:#fff;
                transition: margin .3s;

                &.error{
                    display: none;
                }
            }

            @include pseudo-block(){
                height: 1px;
                width: 100%;
                bottom:0;
                background: #999;
            }

            @include pseudo-block('after'){
                height: 2px;
                width: 0;
                bottom:0;
                background: #fff;
                transition: width .3s;
            }
            @include stretched;
        }

        input{
            width: 100%;
            background: none;
            border:0;
            height: 20px;
            padding: 5px 0;
            color:#ccc;
        }
        
        input:focus, .input-filled{
            & + label{
                &::after{
                    width: 100%;
                }
                span{
                    margin-top: -15px;
                }
            }
        }

        input.error + label{
            span{
                &.label{
                    display: none;
                }
                &.error{
                    display: block;
                    color:#E2001A;
                    font-family: Arial;
                    font-size: 14px;
                    letter-spacing: 0;
                }
            }
            &::after{
                background: #E2001A;
            }
        }

        @include media('<=phone'){
            label span{
                font-size: 15px;
                line-height: 28px;
            }
            margin-bottom: 20px;
        }
    }

    button{
        border:2px solid #E2001A;
        padding:10px 30px;
        color:#fff;
        cursor:pointer;
        background: none;
        text-transform: uppercase;
        font-family: 'bebas_neuebook';
        font-size: 18px;
        letter-spacing: 2px;
        position: relative;
        min-width: 120px;

        @include pseudo-block(){
            width: 100%;
            bottom:0;
            left:0;
            z-index: -1;
            top:100%;
            background: #E2001A;
            transition: top .3s;
        }

        &:hover::before{
            top:0;
        }
    }
}



#already_client{
    .form-text{
        margin-right:140px;
    }
    button{
        position: absolute;
        right:20px;
        top:6px;
    }
    form{
        transform: translate(-50%, -50%) !important;
    }
    @include media("<=phone"){
        .form-text{
            margin-right:0;
        }
        button{
            position: relative;
            right: auto;
            bottom: auto;
        }
    }
}

.forms{
    @include stretched;
    top: 80px;

    @include media('height<=phone'){
        form#get_in_touch{
            position: relative;
            top:0;
            transform: translate(-50%, 80px);
            margin-bottom: 50px;
        }
    }
}

@include media('height<=phone'){
    body{
        overflow: visible;
    }
}

.menu-toggle{
    width: 40px;
    height: 40px;
    border:0;
    position: absolute;
    background: none;
    top:39px;
    right:0;
    display: none;
    @include media('<628px'){
        display: block;
    }
    .lines{
        position: absolute;
        width: 22px;
        height:2px;
        left:8px;
        top:18px;
        background: #fff;
        transition:  background .3s;
        
        &::before, &::after{
            display: block;
            content: "";
            position: absolute;
            width: 22px;
            height:2px;
            left:0;
            background: #fff;
            transition: margin .3s .2s, transform .3s;
            
        }
        &::before{
            margin-top: -7px;
        }
        &::after{
            margin-top: 7px;
        }
    }
}
.mobile-menu-visible .menu-toggle .lines{
    background: none;
            
    &::before, &:after{
        transition: margin .3s, transform .3s .2s;
        margin-top:0;
    }
    &::before{                  
        transform: rotate(45deg);
    }
    &::after{
        transform: rotate(-45deg);
    }
}
